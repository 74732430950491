function copyToClipboard() {
	const accessCode = document.querySelector("#accessCode");

	navigator.clipboard.writeText(accessCode.textContent.trim());
}

document.addEventListener("DOMContentLoaded", () => {
	const menuButton = document.querySelector( ".mobile-menu-button" )
	if ( menuButton ) {
		const menu = new Mmenu( ".mobile-navigation-menu", {
			extensions: [
				"position-right"
			],
			navbar: {
				add: true,
				title: ""
			}
		} )

		menuButton.addEventListener( "click", () => {
			if ( !menu.vars.opened ) {
				menu.API.open()
			}
			else {
				menu.API.close()
			}
		} )
	}


	if ( document.querySelector( "body" ).clientWidth <= 767 ) {
		$(".inside-content-background" ).fitVids({ ignore: "nofit" });
	}

	document.querySelector("#alert-close").onclick = function() {
		document.querySelector("#alert").style.display = "none";
	}
} )


jQuery(document).ready(function($) {
	$( ".alert-exit-icon" ).click(function() {
		$(".alert-header-section").hide();
		setCookie("alertRegion", "isShown");
	});

	if ( document.cookie.indexOf("alertRegion=") == -1 ) {
		$(".alert-header-section").show();
	}

	personalization.init();
	personalization.login();

	// Mini dropdown menu
	$( ".mini-menu-dropdown-toggle" ).click(function() {
		$('.mini-menu-dropdown').toggleClass('open');
		$( ".mini-menu-dropdown-list" ).slideToggle( "slow");
	});
});

// Calculate alert banner top margin
$(window).on('load resize', function() {
	calculateAlertTopMargin();
});

function calculateAlertTopMargin() {
	$(".alert-header-section").css("top", $(".navbar").outerHeight());
}

function setCookie(cname, cvalue) {
	document.cookie = cname + "=" + cvalue + ";path=/";
}
